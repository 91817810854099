import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { 
  FileText,
} from 'react-feather';





const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;




export const ResponsiveWrapper = styled.div`
width: 100%;
margin: 0px 0px 0px 0px;
padding: 0px;

display: flex;
flex-direction: column;
justify-content: flex-start;
align-contents: space-between;
border-radius: 20px;
border: 1.5px solid white;
max-width: 800px;

@media (min-width: 768px) {
  flex-direction: row;
  
}
`;

export const StyledLogo = styled.img`
  width: 250px;
  margin-left: 20px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 400px;
    margin-left: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;


export const StyledImg = styled.img`
  
  display: none;
  width: 100%;
  transition: width 0.5s;
  border-radius: 0px 0px 20px 20px;
  
  @media (min-width: 768px) {
  display: flex;
  border-radius: 0px 20px 20px 0px;
  }
`;

export const StyledImgM = styled.img`
  width: 100%;
  transition: width 0.5s;
  border-radius: 0px 0px 20px 20px;

  @media (min-width: 768px) {
  display: none;
  border-radius: 20px 0px 0px 20px;
  }
`;

export const Psh = styled.img`
  display: none;
  transition: width 0.5s;
  width: 48px;
  height: 48px;
  filter: invert(1); 

  position: fixed;
  margin: 20px 20px;
  bottom:0px;
  right:0px;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ paddingTop: 250, backgroundColor: "var(--primary)" }}
      >


        <StyledLogo alt={"logo"} src={"/config/images/logo.svg"} />

        <s.TextTitle
                  style={{ paddingTop: 150, textAlign: "center", color: "var(--accent-text)" }}
                >
                       <a className="nav-link" href="mailto:contact@thaidefenseexchange.com">
                   <s.MenuLink>contact@thaidefenseexchange.com</s.MenuLink></a>

          </s.TextTitle>

      </s.Container>


 

        <s.TextInfo
                  style={{ paddingBottom: 50, textAlign: "center", color: "var(--accent-text)" }}
                >
                  Copyright © 2024 Thai Defense Exchange
                </s.TextInfo>
 


    </s.Screen>
  );
}

export default App;
